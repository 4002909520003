// Angular Import
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// project import
import { AdminComponent } from './theme/layout/admin/admin.component';
import { GuestComponent } from './theme/layout/guest/guest.component';
import SignInComponent from './features/authentication/sign-in/sign-in.component';
import DashAnalyticsComponent from './features/dashboard/dash-analytics.component';



const routes: Routes = [
  { path: '',
  component: SignInComponent},  
      {
        path: 'home',
        //loadComponent: () => import('./theme/layout/admin/admin.component')
        component: AdminComponent,
        children: [
          {
            path: 'analytics',
            component: DashAnalyticsComponent
          },
         
        ]
      }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
