export const serviceConstant = {
    LOGIN_SERVICE_API_PATH: "http://103.127.29.24:8081/api/login",
    LOGIN_SERVICE_MARQUEE_API_PATH: "/newsticker/",
    LOOK_UP_DETAILS_API_PATH: "/api/user-service/lookupDetails/bylookuptype/{0}",
    TAB_BY_ROLE_API_PATH: "/api/user-service/user/tabs/tabForRole/{0}",
    FAS_DETAILS_EBIP_API_PATH: "/api/matrix-service/fasdetails/",
    CREATE_PROJECT_METRICS_API_PATH: "/api/matrix-service/projects/",
    UPDATE_PROJECT_METRICS_API_PATH: "/api/matrix-service/projects/{0}",
    CREATE_MODULE_METRICS_API_PATH: "/api/matrix-service/modules/moduleMilestone/projectId/{0}",
    CREATE_SPRINT_METRICS_API_PATH: "/api/matrix-service/projectinfoagile/projectId/{0}",
    CREATE_UM_DETAILS_API_PATH: "/api/matrix-service/umdetails/projectId/{0}",
    CREATE_LIVE_NODE_API_PATH: "/api/matrix-service/livenodedata/projectId/{0}",
    CREATE_TESTCASE_API_PATH: "/api/matrix-service/testcases/projectId/{0}",
    CREATE_DEFECT_API_PATH: "/api/matrix-service/defectdata/projectId/{0}",
    CREATE_KPI_PERFORMANCE_API_PATH: "/api/matrix-service/ragstatus/projectId/{0}",
    CREATE_FAS_DETAILS_API_PATH: "/api/matrix-service/fasdetails/idandname/",
    CREATE_USER_API_PATH: "/api/user-service/users/",
    CREATE_VENDOR_API_PATH: "/api/vendor-service/vendordetails/",
    GET_KPI_METRICS_API_PATH: "/api/matrix-service/projects/{0}",
    SEARCH_PROJECT_KPI_DASHBOARD_API_PATH: "/api/matrix-service/projectssumary/signum/{0}/roleId/{1}/?",
    ALL_KPI_DATA_BULK_API_PATH: "/api/matrix-service/projects/getAll/signum/{0}/roleId/{1}/?",
    HIRING_DEMAND_API_PATH: "/api/ewfm-service/demandCreation/",
    ORDER_GRAPHICAL_DASHBOARD: "/api/ewfm-service/orderManagement/orderstatuswisereport/{0}/{1}/{2}/{3}/{4}/{5}/{6}",
    ORDER_GRAPHICAL_AGING_REPORT_API_PATH: "/api/ewfm-service/orderManagement/orderagingwisereport/{0}/{1}/{2}/{3}/{4}/{5}/{6}",
    ORDER_GRAPHICAL_FEEDBACK_REPORT_API_PATH: "/api/ewfm-service/orderManagement/orderreportforpie/{0}/{1}/{2}/{3}/{4}/{5}/{6}/{7}",
    JR_DASHBOARD_API_PATH: "/api/ewfm-service/report/hiringreport/{0}/{1}/{2}/",
    UTIL_PERCENT_API_PATH: "/api/ewfm-service/demandCreation/{0}/{1}",
    DEMAND_LIST_API_PATH: "/api/ewfm-service/demandCreation/{0}",
    EDIT_DEMAND_LIST_API_PATH: "/api/ewfm-service/demandCreation/findbyunid/{0}",
    AUDIT_DEMAND_API_PATH: "/api/ewfm-service/audittrailfordemand/findbyuniqueid/{0}/{1}",
    JR_ALLOCATION_SEARCH_DOMAIN_LEVEL_API_PATH: "/api/ewfm-service/jrallocation/domainviewforjrallocation/{0}/{1}",
    JR_ALLOCATION_SEARCH_SUB_DOMAIN_LEVEL_API_PATH: "/api/ewfm-service/jrallocation/subdomainviewforjrallocation/{0}/{1}/{2}",
    JR_ALLOCATION_SEARCH_HIRING_MANAGER_LEVEL_API_PATH: "/api/ewfm-service/jrallocation/hmviewforjrallocation/{0}/{1}/{2}/{3}",
    JR_ALLOCATION_UNIQUE_ID_LEVEL_API_PATH:"/api/ewfm-service/jrallocation/getuniqueidhmwise/{0}/{1}/{2}/{3}",
    JR_NO_GETTING_API_PATH: "/api/ewfm-service/jrallocation/jrdtailsbyjrno/{0}",
    JR_ALLOCATION_SIGNUM_LIST_LEVEL_API_PATH:"/api/ewfm-service/jrallocation/getsignumlist",
    ORDER_NAME_SIGNUM_LIST_API_PATH: "/api/ewfm-service/ohrms/getallempWithSignum/{0}",
    ORDER_ALL_HRMS_SIGNUM_LIST: "/api/ewfm-service/ohrms/getallemployeenamewithSignum",
    ORDER_SIGNUMLIST_API_PATH:"/api/ewfm-service/orderManagement/getsignumlistorder",
    IJP_SIGNUMLIST_API_PATH: "/api/ewfm-service/ijp/getsignumlistIJP",
    JR_ALLOCATION_CALCULATE_MHR_LEVEL_API_PATH:"/api/ewfm-service/jrallocation/getcalculatedmhr/{0}/{1}",
    JR_UPDATE_API_PATH:"/api/ewfm-service/jrallocation/updatejrdetails/{0}",
    JR_CANCELLATION_API_PATH:"/api/ewfm-service/jrallocation/cancleJrId/{0}",
    JR_ALLOCATE_API_PATH: "/api/ewfm-service/jrallocation/",
    ARP_CANDIDATE_UPDATESTATUS_API_PATH: "/api/ewfm-service/arpcandidate/updatestatusofapplicant/{0}/{1}",
    HIRING_DEMAND_VIEW_API_PATH: "/api/ewfm-service/demandview/demandcreationview/{0}/{1}/{2}/{3}",
    HIRING_DEMAND_VIEW_CHILD1_API_PATH: "/api/ewfm-service/demandview/demandcreationutilized/{0}/{1}/{2}/{3}",
    HIRING_DEMAND_VIEW_CHILD2_API_PATH: "/api/ewfm-service/demandview/demandcreationview/{0}/{1}/{2}/{3}",
    CREATE_TESTPHASE_DETAILS_API_PATH : "/api/matrix-service/testingphasedetails/projectId/{0}",
    CREATE_SUPPORT_DETAILS_API_PATH : "/api/matrix-service/support/projectId/{0}",
    CREATE_NACI_DETAILS_API_PATH : "/api/matrix-service/nacidetails/projectId/{0}",
    CREATE_IUDM_DETAILS_API_PATH : "/api/matrix-service/iudmdetails/projectId/{0}",
    CREATE_ENM_DETAILS_API_PATH : "/api/matrix-service/enmdetails/projectId/{0}",
    SEARCH_USER_DETAILS_API_PATH : "/api/user-service/users/",
    ATTRITION_GET_TABLE_VALUE_API_PATH: "/api/ewfm-service/attrition/getattritionviewcountrywise/{0}",
    ATTRITION_UPDATE_API_PATH: "/api/ewfm-service/attrition/updateattritioncountrydata/{0}/{1}",
    IJP_HM_POPULATE_PATH: "/api/ewfm-service/ijp/getallbdgsempList",
    IJP_CREATION_DASHBOARD_VIEW: "/api/ewfm-service/ijp/ijpdashboardview/{0}/{1}",
    IJP_CREATION_API_PATH: "/api/ewfm-service/ijp/",
    ARP_CREATION_API_PATH: "/api/ewfm-service/arp/",
    ARP_CREATION_SENDMAIL_API_PATH: "/api/ewfm-service/arp/createSendEmail/",
    ARP_APPLICANT_DETAILS_GET_BY_APPLICANT_ID_API_PATH:"/api/ewfm-service/arpcandidate/{0}",
    ARP_DETAILS_GET_BY_ID_API_PATH: "/api/ewfm-service/arp/{0}",
    IJP_DETAILS_APPROVED_API_PATH: "/api/ewfm-service/ijp/getallapprovedijp/",
    IJP_DETAILS_GET_BY_ID_API_PATH: "/api/ewfm-service/ijp/{0}",
    IJP_UPDATE_API_PATH_MAIL_CIRCULATION: "/api/ewfm-service/ijp/mailcirculation/{0}",
    IJP_PREVIEW_CREATION: "/api/ewfm-service/ijp/previewforijpcreation/{0}",
    IJP_GET_COUNTRY_DOMAIN_BY_SIGNUM: "/api/ewfm-service/ijp/getcountryanddomain/{0}",
    IJP_SEARCH_GET_APPLICANTS_DETAIL_BY_SIGNUM : "/api/ewfm-service/ijp/getapplicantdetailsforijp/{0}/{1}",
    IJP_DETAILS_FINALIZED_CLOSED_API_PATH: "/api/ewfm-service/ijp/getallfinalizeandclosedIjp/",
    IJP_DETAILS_ALL_IJP_HIRING_MANAGER: "/api/ewfm-service/ijp/getallhmijp/{0}",
    GET_APPLICANT_DETAILS_BY_APPLICANT_ID: "/api/ewfm-service/applicant/{0}",
    UPLOAD_CV_APPLICANT :"/api/user-service/fileupload/",
    IJP_FINALIZE_CLOSE:"/api/ewfm-service/ijp/ijpfinalizedclosed/{0}/{1}/{2}",
    DOWNLOAD_CV_APPLICANT_WITHOUT_TOKEN :"/api/user-service/fileupload/downloadcv/{0}/{1}",
    DOWNLOAD_ARP_HIRING_CV_APPLICANT_WITHOUT_TOKEN:"/api/user-service/fileupload/downloadjdfile/{0}",
    DOWNLOAD_BASE_DATA_FILE_WITHOUT_TOKEN :"/api/user-service/fileupload/downloadfile/{0}/{1}",
    DOWNLOAD_GENERALIZED_REPORT_DUMP_API_PATH :"/api/ewfm-service/demandview/getdumpforexcel/{0}/{1}/{2}",
    JR_POOL_1ST_VIEW:"/api/ewfm-service/demandview/jrviewcountrywise/{0}",
    JR_POOL_AVAILABLE_VIEW:"/api/ewfm-service/demandview/availablejrview/{0}/{1}/{2}",
    JR_POOL_ALLOCATED_VIEW:"/api/ewfm-service/demandview/availablejrview/{0}/{1}/{2}",
    JR_POOL_ALLOCATED_2ND_VIEW:"/api/ewfm-service/demandview/allocatejrviewdomainwise/{0}/{1}/{2}",
    FETCH_UNIQUE_ID_LIST_BY_SUBDOMAIN_ID:"/api/ewfm-service/ijp/getuniqueidforijp/{0}",
    UPDATE_APPLICANT_DATA_BEFORE_CLOSE:"/api/ewfm-service/applicant/updatedataofapplicant/{0}",
    GET_TAB_DETAILS :"/api/user-service/user/tabs/",
    ADD_ROLE_LINK_MAP:"/api/user-service/roleLinkMaps/",
    ADD_ROLE:"/api/user-service/user/roles/",
    SELECT_USER_ROLES : "/api/user-service/user/roles/",
    SEARCH_USER_BY_SIGNUM :"/api/user-service/users/user/",
    SEARCH_VENDOR_BY_EMAILID:"/api/vendor-service/vendordetails/vendorbyemailid/",
    ACCEPT_REJECT_IJP_API_PATH: "/api/ewfm-service/ijp/ijpacceptreject/{0}/{1}/{2}/{3}",
    REPORT_MAIL_SELF_OR_ALL_API_PATH: "/api/ewfm-service/report/sendutilizationmail/{0}/{1}",
    MAIL_CC_API_PATH: "/api/ewfm-service/report/getmaillistforhiringreport/{0}",
    UPDATE_ROLE_LINK_MAP:"/api/user-service/roleLinkMaps/{0}",
    FILE_UPLOAD_SERVICE_PATH: "/api/user-service/fileupload/",
    PREVIEW_MAIL_CIRCULATION: "/api/ewfm-service/ijp/previewmail/{0}/{1}",
    PREVIEW_MAIL_CIRCULATION_FOR_ARP: "/api/ewfm-service/arp/updatedVendormail/{0}/{1}/{2}", //"/api/ewfm-service/arp/vendormail/{0}/{1}/{2}",
    HIRING_FILE_UPLOAD: "/api/ewfm-service/hiringfileupload/",
    JR_FILE_UPLOAD: "/api/ewfm-service/jrfileupload/",
    FETCH_FILE_UPLOAD_HISTORY: "/api/user-service/fileupload/getfilehistory/",
    UPDATE_FILE_UPLOAD_HISTORY: "/api/user-service/fileupload/utilization/processinghrmsfiles/signum/{0}",
    DOMAIN_DETAILS_API_PATH: "/api/utilization-service/domainlist/",
    FETCH_RECIPIENT_API_PATH : "/api/utilization-service/recipientlist/cdId/{0}",
    SEND_UTILIZATION_MAIL_API_PATH : "/api/utilization-service/utilizationMail/",
    GET_USER_DETAIL_API_PATH : "/api/utilization-service/hrms/gethrmsbysignum/",
	ARP_CREATION_GET_USER_DETAILS: '/api/ewfm-service/arp/getcountrydomainandsubdomain/{0}/',
    ARP_CREATION_GET_MAIL_LIST_DETAILS: '/api/ewfm-service/arp/getarpundersignum/{0}/{1}',
    ARP_FINALIZE_CLOSE_DETAILS_LIST:'/api/ewfm-service/arp/hiringmanagerlist/{0}/{1}',
    ARP_FETCH_CANDIDATE_LIST:"/api/ewfm-service/arp/{0}",
    ARP_FETCH_CANDIDATE_DATA_LIST:"/api/ewfm-service/arpdata/getArpDetailsByArpId/{0}",
    ARP_FETCH_CANDIDATE_DATA_LIST_STATUS:"/api/ewfm-service/arpdata/getArpDetailsByArpIdEmpStatus/{0}/{1}",
    UPDATE_APPLICANT:"/api/ewfm-service/arpcandidate/updatedataofapplicant/{0}",
    All_APPLICANT:"/api/ewfm-service/arpdata/getAllARPDetails",
    ARP_JR:'/api/ewfm-service/jrallocation/arpjrdetails/',
    AVAILABLE_JR: "/api/ewfm-service/availablejr/{0}/{1}/{2}/{3}/",
    DELETE_PROJECT_PATH_IN_EDIT_VIEW:"/api/matrix-service/projects/signum/{0}/roleId/{1}/delete/{2}/deletedFlag/{3}",
    FILE_SOURCE_UPDATE_PATH: "/api/user-service/fileupload/deleterequest/{0}/signum/{1}",
    EXTENSION_OLD_ID_FETCH: "/api/ewfm-service/orderManagement/getoldorders/{0}/{1}/{2}/{3}/{4}/{5}",
    ORDER_CREATION: "/api/ewfm-service/orderManagement/save",
    ORDER_DRAFT: "/api/ewfm-service/orderManagement/saveTempOrders",
    GET_CU_LIST_SIGNUM_MAP: "/api/ewfm-service/orderManagement/getOrderCustomerBySignum/{0}",
    GET_ALL_ORDER: "/api/ewfm-service/orderManagement/getAllOrderManagementDetails",
    GET_ALL_ORDER_DRAFT: "/api/ewfm-service/orderManagement/getAllTempOrderManagementDetails",
    GET_ORDER_BY_ID: "/api/ewfm-service/orderManagement/getOrderManagementById/{0}",
    GET_ORDER_BY_ID_DRAFT: "/api/ewfm-service/orderManagement/getTempOrderManagementById/{0}",
    GET_ORDER_BY_SECRET: "/api/ewfm-service/orderManagement/getorderbysecret/{0}",
    UPDATE_FEEDBACK: "/api/ewfm-service/orderManagement/updatefeedback",
    UPDATE_ORDER_BY_ID: "/api/ewfm-service/orderManagement/update/{0}",
    ARP_FINALIZE_PATH: "/api/ewfm-service/arp/finalize/{0}/{1}",
    ARP_PREPARE_DOWNLOAD_DATA_PATH: "/api/ewfm-service/arpdata/loadbulkdatatemplate",
    RECALL_API_PATH: "/api/ewfm-service/arp/recall/",
    GET_ALL_COSTCENTER_PATH : "/api/utilization-service/costcenter/",
    ADD_COSTCENTER_DETAILS : "/api/utilization-service/costcenter/",
    ARP_OPEN_POS_MAIL__API_PATH: "/api/ewfm-service/report/sendMailForOpeningReport/{0}/{1}",
    GET_ALL_VENDOR_API_PATH: "/api/vendor-service/vendordetails/",
    GET_ALL_VENDOR_API_PATH_BOT: "/api/vendor-service/botProfileUpload/getAllVendor",
    MONTHLY_JOINING_REPORT_API_PATH: "/api/ewfm-service/report/sendMailForARPJoinningReport/{0}/{1}/{2}",
    GET_ALL_VENDOR_LIST_PATH : "/api/eureka-zuul-service/vendordetails/",
    ADD_PROFILE_UPLOAD_API_PATH : "/api/ewfm-service/vendorfileupload/",
    SEND_PROFILE_UPLOAD_API_PATH :"/api/vendor-service/botProfileUpload/profileUpload",
    GET_ALL_OPEN_ORDER: "/api/ewfm-service/orderManagement/getOrderDetailsByType/{0}/{1}/{2}",
    CREATE_ARP_USER_API_PATH: "/api/ewfm-service/arpdata/save/",
    GET_ARP_USER_API_PATH: "/api/ewfm-service/arpdata/getARPdataById/",
    GET_Job_Stage_API_PATH:"/api/ewfm-service/arpdata/fetchJobstageByRoleId/",
    GET_MHR_API_PATH: "/api/ewfm-service/arpdata/fetchratebyrolejobstage/{0}/{1}/{2}",
    GET_APPLICANT_BY_EMP_STAUS:"/api/ewfm-service/arpdata/getAllARPDetailsByEmpStatus/{0}"
};
