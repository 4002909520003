export const routeConstant = {
    LOGIN_ROUTING_PATH: "login",
    COST_CENTER_ROUTING_PATH: "costCenter",
    HOME_ROUTING_PATH: "home",
    WELCOME_ROUTING_PATH: "welcome",
    KPI_DASHBOARD_ROUTING_PATH: "kpiDashboard",
    KPI_DASHBOARD_SEARCH_PROJECT_ROUTING_PATH: "searchProjectKpiDashboard",
    KPI_DASHBOARD_UPDATE_ROUTING_PATH: "editKpiDashboard",
    KPI_DASHBOARD_VIEW_ROUTING_PATH: "viewKpiDashboard",
    KPI_DASHBOARD_FILE_UPLOAD_ROUTING_PATH: "kpiFileUpload",
    USER_ROUTING_PATH: "user",
    ROLE_ROUTING_PATH: "role",
    CREATE_DEMAND_ROUTING_PATH: "createDemand",
    BLANK_ROUTING_PATH: "",
    FULL_CHECK_PATH_FOR_ROUTING: "full",
    DELIMITER_SLASH_FOR_PATH: "/",
    ATTRITION_ROUTING_PATH: "attrition",
    JR_DASHBOARD_ROUTING_PATH: "jrDashboard",
    JR_UPDATION_ROUTING_PATH: "jrUpdation",
    JR_ALLOCATION_ROUTING_PATH: "jrAllocation",
    IJP_CREATION_ROUTING_PATH: "ijpCreation",
    ARP_HIRING: 'arphiring',
    ARP_HIRING_DETAIL: 'arphiringdetails',
    ARP_MAIL_CIRCULATION: 'arpmailcirculation',
    ARP_MAIL_CIRCULATION_DETAILS: 'arpmailcirculationdetails',
    ARP_CANDIDTE_DETAILS_STATUS_WISE: 'arpcandidatedetailsstatuswise',
    VENDOR_CREATION: 'arpvendorlogin',
    FINALIZE_AND_CLOSE_IJP: "finalized",
    SEARCH_IJP: "searchIJP",
    IJP_CREATION_DETAILS_ROUTING_PATH: "ijpCreationDetails",
    IJP_MAIL_CIRCULATION_DASHBOARD_ROUTING_PATH: "mailCirculationDashboard",
    IJP_MAIL_CIRCULATION_DETAILS_ROUTING_PATH: "mailCirculationDetails",
    FINALIZE_CLOSE_DETAILS: "finalizeCloseDetails",
    DEMAND_VIEW_ROUTING_PATH: "viewDemand",
    HM_CREATION_ROUTING_PATH: "hiringManagerCreation",
    JR_CANCELLATION_ROUTING_PATH: "jrCancellation",
    IJP_ACTION_ROUTING_PATH: "ijpAction",
    JR_REPOSITORY_ROUTING_PATH: "jrRepository",
    APP_ACTION_ROUTING_PATH: "appExtAction",
    REPORT_GENERALIZED_ROUTING_PATH: "generalized",
    EDIT_ROLE: "roleEdit",
    HIRING_DASHBOARD_FILE_UPLOAD_ROUTING_PATH: "hiringFileUpload",
    JR_DASHBOARD_FILE_UPLOAD_ROUTING_PATH: "jrFileUpload",
    UTILIZATION_MAIL_CIRCULATION: "utilizationMailCirculation",
    UTILIZATION_FILE_UPLOAD: "utilizationFileUpload",
    THREE_PPP_DASHBOARD:"3PPDashboard",
    ARP_FINALIZE_AND_CLOSE:"arpfinalizeclosearp",
    ROLLOUT_OFFER:'rolloutoffer',
    ROLLOUT_OFFER_DETAILS:'rolloutofferdetails',
    UPDATE_OFFER:'updateoffer',
    UPDATE_OFFER_DETAILS:'updateofferdetails/:jrid',
    DASHBOARD_ROUTING_PATH: "dashboard",
    ORDER_MANAGEMENT_DASHBOARD: "ordermanagementdashboard",
    ORDER_GRAPHICAL_DASHBOARD: "OrderGraphicalDashboardComponent",
    ORDER_MANAGEMENT: "ordermanagement",
    ORDER_MANAGEMENT_LIST: "ordermanagementlist",
    ORDER_MANAGEMENT_HOME: "ordermanagementhome",
    ORDER_MANAGEMENT_FEEDBACK: "orderfeedback",
    ORDER_MANAGEMENT_DETAILS: "ordermanagement/:id",
    ORDER_UNSUBMIT_DETAILS: "orderunsubmit/:id",
    KPI_PERFORMANCE_FILE_UPLOAD_ROUTING_PATH: "performanceFileUpload",
    OPENING_REPORT_PATH: "openingreport",
    MONTHLY_JOINING_REPORT_PATH : "monthlyjoiningreport",
    PROFILE_UPLOAD_PATH : "profileupload",
    PROFILE_UPLOAD_PATH_OPEN : "public/profileupload",
    ORDER_MANAGEMENT_OPEN_LIST: "ordermanagementopenlist",
    ORDER_MANAGEMENT_CLOSED_LIST: "ordermanagementcloselist",
    CREATE_ARP_ROUTING_PATH: "createarp",
    DRAFT_ORDERS_LIST: "draftOrder",
    MY_DETAILS_PATH: "myDetails",
    KPI_BULK_DOWNLOAD: "kpibulkdownload",
    //APP_ACTION_ROUTING_PATH: "appExtAction"
    ORDER_HRMS_FILE_UPLOAD: "orderHrmsfile",
    BOT_ITM_AUTOMATION: "botitmautomation",
    ARP_FILE_UPLOAD: "arpfileupload",
    ARP_NOTIFICATION_FILE_UPLOAD: "arpdatanotification",
    EMAILID_ADD:"emailidadd",
    LM_B0_DASHBOARD:"lmbodashboard",
};