// angular import
import { Component, ElementRef, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, RouterModule } from '@angular/router';
import { applicationConstant } from '../../../constants/application-constant';
import { routeConstant } from '../../../constants/route-constant';

// project import
import { SharedModule } from 'src/app/theme/shared/shared.module';
import { LoginService } from './login.service';
import { IDropdownSettings, NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';

@Component({
  selector: 'app-sign-in',
  standalone: true,
  imports: [SharedModule, RouterModule,NgMultiSelectDropDownModule ],
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss']
})
export default class SignInComponent implements OnInit{

  loginForm!: FormGroup;
  listOfSociety:any=[];
  loginSuccess:boolean=false;
  dropdownSettings: IDropdownSettings = {};
  societyList:any=[];
  selectedItems:any;
  responseObject:any;

  constructor( private router: Router, private fb: FormBuilder, private el: ElementRef, private service: LoginService
  ) {
    this.loginForm = this.fb.group({
      user_id: ['', [Validators.required]],
      password: ['', [Validators.required]],
      society:['']
      
    });




  }

  ngOnInit(): void {
    //throw new Error('Method not implemented.');
    console.log("");
    this.dropdownSettings = {
      singleSelection: true,
      idField: 'item_id',
      textField: 'item_text',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true
    };
  }

  onItemSelect(item: any) {
    console.log(item);
  }
  signIn(param:number){

    if(param==0){
    this.service.validateUser(this.loginForm.value).subscribe((resp:any)=>{
      console.log(resp);
      if(!resp?.login_flag){
        console.log("resp is:-"+resp.message);
      }else{
        
        this.loginSuccess=true;
        this.responseObject=resp.user;
        this.listOfSociety=resp.user.societyWithLinks;
        for (let index = 0; index < this.listOfSociety.length; index++) {
          console.log(this.listOfSociety[index].name)
          let temp={"item_id":this.listOfSociety[index].id,"item_text":this.listOfSociety[index].name}
          this.societyList.push(temp);

          
        }
        
        //this.router.navigate([routeConstant.DELIMITER_SLASH_FOR_PATH+ routeConstant.HOME_ROUTING_PATH])
      }
    });
  }else{
    
    console.log("selected society"+this.loginForm.controls['society'].value[0].item_id);
    sessionStorage.setItem(applicationConstant.LOGGED_IN_USER, JSON.stringify(this.responseObject));
   let selectedSociety= this.responseObject.societyWithLinks.filter((item:any)=>item.id==this.loginForm.controls['society'].value[0].item_id)
    sessionStorage.setItem(applicationConstant.LOGGED_IN_SOCIETY,JSON.stringify(selectedSociety[0]));
    sessionStorage.setItem(applicationConstant.SESSION_FULL_NAME_VAR,this.responseObject.name);
    sessionStorage.setItem(applicationConstant.SESSION_EMAIL_VAR,this.responseObject.email);
    sessionStorage.setItem(applicationConstant.SESSION_USER_CONTACT,this.responseObject.contact_no);
    sessionStorage.setItem(applicationConstant.TOKEN,this.responseObject.generatedToken);

    this.router.navigate([routeConstant.DELIMITER_SLASH_FOR_PATH+ routeConstant.HOME_ROUTING_PATH])
    
  }
    

  }
}
