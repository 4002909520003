<div class="auth-wrapper">
  <div class="auth-content">
    <div class="card">
      <div class="row align-items-center text-center">
        <div class="col-md-12">
          <div class="card-body">
            <img src="assets/images/eSolutionLogo.png" alt="logo" class="img-fluid mb-4" />
            <h4 class="mb-3 f-w-400">SignIn</h4>
            <form [formGroup]="loginForm" >
            <div class="input-group mb-3">
              <span class="input-group-text" id="basic-addon1"><i class="feather icon-mail"></i></span>
              <input type="email" class="form-control" placeholder="Email address" aria-label="Username" formControlName="user_id" aria-describedby="basic-addon1" />
            </div>
            <div class="input-group mb-4">
              <span class="input-group-text" id="basic-addon3"><i class="feather icon-lock"></i></span>
              <input type="password" class="form-control" placeholder="Password" aria-label="Username" formControlName="password" for aria-describedby="basic-addon3" />
            </div>
            <div class="input-group mb-4" *ngIf="loginSuccess">
              <span class="input-group-text" id="basic-addon3"><i class="feather icon-lock"></i></span>
              <ng-multiselect-dropdown
  [placeholder]="'Select Society'"
  [settings]="dropdownSettings"
  [data]="societyList" 
  formControlName="society"
>
</ng-multiselect-dropdown>
            </div>
            
            <div class="d-grid" *ngIf="!loginSuccess">
              <button type="button" class="btn btn-block btn-primary mb-4" (click)="signIn(0)">SignIn</button>
            </div>
            <div class="d-grid" *ngIf="loginSuccess">
              <button type="button" class="btn btn-block btn-primary mb-4" (click)="signIn(1)">Proceed</button>
            </div>
          </form>
            <p class="mb-2 text-muted">Forgot password? <a href="javascriprt:" class="f-w-400">Reset</a></p>
           
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
