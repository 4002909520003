import { Injectable } from '@angular/core';
//import { catchError } from 'rxjs/internal/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
//import {  throwError  } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { serviceConstant } from '../../../constants/service-constant';
import { applicationConstant } from '../../../constants/application-constant';
import { Observable } from 'rxjs';
//import { Observable } from 'rxjs-observable';
//import {  Observable } from 'rxjs/dist/types';
//import { Helper } from '../helper/helper.component';

@Injectable({
    providedIn: 'root'
})
export class LoginService {

    constructor(private http: HttpClient) {}
    validateUser(loginobj:any): Observable <any> {
        /*
        var CryptoJS = require("crypto-js");
        const secretKey = applicationConstant.SECRET_KEY;
        const key = CryptoJS.enc.Utf8.parse(secretKey);
        const iv = CryptoJS.enc.Utf8.parse(secretKey);
        if(loginobj && !loginobj.token_azure && loginobj.password){
         let cipherPwd= CryptoJS.AES.encrypt(loginobj.password, key, {
         keySize: 128,
         iv: iv,
         mode: CryptoJS.mode.ECB,
         padding: CryptoJS.pad.Pkcs7
         });
         loginobj.password = cipherPwd.toString();
        }
        if(loginobj.token_azure && !loginobj.otp){
            loginobj.password=null;
            loginobj.signum=null;
        }
        */
        const headers = new HttpHeaders({
            'X-TenantID': '',
            // Add any other headers you need
          });
        return this.http.post(serviceConstant.LOGIN_SERVICE_API_PATH, loginobj,{headers});
    }
/*
    getMarqueeDetail() : Observable < any > {
        return this.http.get(serviceConstant.LOGIN_SERVICE_MARQUEE_API_PATH).pipe(
            map((res: Response) => {
                return res;
            },
            err => {
                return err;
            }),
            catchError((error: any) => {
                return ObservableThrowError(error || 'Server error');
            })
        );
    }
    */

   
}