@if (item.url && !item.external) {
  <li [ngClass]="item.classes" [routerLinkActive]="['active']">
    <a class="nav-link" [target]="item.target ? '_blank' : '_self'" [routerLink]="[item.url]" (click)="closeOtherMenu($event)">
      @if (item.icon) {
        <span class="pcoded-micon"><i class="feather" [ngClass]="item.icon"></i></span>
      }
      @if (item.icon) {
        <span class="pcoded-mtext">{{ item.title }}</span>
      } @else {
        {{ item.title }}
      }
    </a>
  </li>
}
@if (item.url && item.external) {
  <li [ngClass]="item.classes">
    <a [target]="item.target ? '_blank' : '_self'" routerLink="item.url">
      @if (item.icon) {
        <span class="pcoded-micon"><i class="feather" [ngClass]="item.icon"></i></span>
      }
      @if (item.icon) {
        <span class="pcoded-mtext">{{ item.title }}</span>
      } @else {
        {{ item.title }}
      }
    </a>
  </li>
}
