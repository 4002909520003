<div class="h-list-header">
  <h6>{{ chatMessage.name }}</h6>
  <a href="javascript:" class="h-back-user-list" (click)="this.ChatToggle.emit()"><i class="feather icon-chevron-left"></i></a>
</div>

<div class="h-list-body">
  <div class="main-chat-cont">
    <div class="main-friend-chat">
      @if (chatMessage.chat) {
        <ng-scrollbar style="height: calc(100vh - 170px)" visibility="hover">
          @for (messages of chatMessage.chat; track messages) {
            <div>
              <div class="media chat-messages">
                @if (messages.type) {
                  <a class="media-left photo-table" href="javascript:">
                    <img class="media-object img-radius img-radius m-t-5" src="{{ chatMessage.photo }}" alt="{{ chatMessage.name }}" />
                  </a>
                }
                <div class="media-body" [ngClass]="{ 'chat-menu-content': messages.type, 'chat-menu-reply': !messages.type }">
                  <div class="">
                    <p class="chat-cont">{{ messages.msg }}</p>
                  </div>
                  <p class="chat-time">{{ messages.time }}</p>
                </div>
              </div>
            </div>
          }
          <div #newChat [innerHTML]="newReplay"></div>
          @if (friendWriting) {
            <div class="media chat-messages typing">
              <a class="media-left photo-table" href="javascript:"
                ><img class="media-object img-radius img-radius m-t-5" src="{{ chatMessage.photo }}" alt="{{ chatMessage.name }}"
              /></a>
              <div class="media-body chat-menu-content">
                <div class="rem-msg">
                  <p class="chat-cont">Typing . . .</p>
                </div>
                <p class="chat-time">now</p>
              </div>
            </div>
          }
        </ng-scrollbar>
      } @else {
        <div class="media chat-messages text-center">
          <div class="media-body chat-menu-content">
            <div class="">
              <p class="chat-cont">CHAT NOT FOUND</p>
            </div>
          </div>
        </div>
      }
    </div>
  </div>
</div>
<div class="h-list-footer">
  <div class="input-group" (focusout)="this.message_error = false">
    <input type="file" class="chat-attach" style="display: none" />
    <a href="javascript:" class="input-group-prepend btn btn-success btn-attach" [ngClass]="{ 'btn-danger': message_error === true }">
      <i class="feather icon-paperclip"></i>
    </a>
    <input
      type="text"
      [(ngModel)]="message"
      name="h-chat-text"
      class="form-control h-send-chat"
      placeholder="Write hear . . "
      (keyup)="sentMsg(1)"
      (keyup.enter)="sentMsg(0)"
    />
    <button
      type="submit"
      class="input-group-append btn-send btn btn-primary"
      [ngClass]="{ 'btn-danger': message_error === true }"
      (click)="sentMsg(0)"
    >
      <i class="feather icon-message-circle"></i>
    </button>
  </div>
</div>
